import type { Notification } from "@/models";
import cx from "classnames";
import { Suspense, useEffect } from "react";
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from "@ionic/react";
import { Icon } from "@urbint/silica";
import { personOutline, ticketOutline, calendarOutline } from "ionicons/icons";
import { Redirect, Route, useLocation } from "react-router-dom";
import { MapPage } from "@/pages/MapPage";
import { usePublicConfig } from "@/api";
import { NotificationIcon } from "@/components/NotificationIcon";
import {
  useReportUserLocationUpdatesToFleet,
  useOnlineStatus,
  useDamagePreventionAuth,
} from "@/hooks";
import { OfflineBanner } from "@/components/OfflineBanner";
import {
  EventsPage,
  NotificationsPage,
  TicketPage,
  TicketHistoryPage,
  ProfilePage,
  TicketQueuePage,
  TicketPrintPage,
} from "./pages/pagesExporter";
import { Loading } from "./common/Loading";
import { THEME_COLOR_LOCATIONS, META_THEME } from "./AppMobile.constants";

const AppMobile = ({
  notifications = [],
}: {
  notifications: Notification[];
}) => {
  const { data: publicConfig } = usePublicConfig();
  const isOnline = useOnlineStatus();
  const { currentUser } = useDamagePreventionAuth();

  // Initialize the user fleet tracking/update
  useReportUserLocationUpdatesToFleet();

  const location = useLocation();
  const withNavigation = !location?.pathname.includes("/ticketPrint");

  useEffect(() => {
    if (
      THEME_COLOR_LOCATIONS.some((path) => location.pathname.includes(path))
    ) {
      const metaTag = document.createElement("meta");
      metaTag.name = META_THEME.name;
      metaTag.content = META_THEME.content;
      document.head.appendChild(metaTag);
    }

    return () => {
      const existingMetaTag = document.head.querySelector(
        'meta[name="theme-color"]'
      );
      if (existingMetaTag) {
        document.head.removeChild(existingMetaTag);
      }
    };
  }, [location]);

  const unreadCount = notifications.reduce(
    (acc, cur) => (cur.read ? acc : acc + 1),
    0
  );

  return (
    <>
      <OfflineBanner />
      <IonTabs className="relative h-full">
        <IonRouterOutlet>
          <Redirect from="/settings" to="/" exact />
          <Suspense fallback={<Loading />}>
            <Route path="/" exact>
              {currentUser.canAccessTickets ? (
                <MapPage />
              ) : currentUser.canAccessNonTicketsEvents ? (
                <EventsPage />
              ) : null}
            </Route>
            <Route path="/events" exact>
              {currentUser.canAccessNonTicketsEvents ? (
                <EventsPage />
              ) : (
                <Redirect to="/" />
              )}
            </Route>
            <Route path="/me" exact component={ProfilePage} />
            <Route
              path="/notifications"
              exact
              render={() => <NotificationsPage notifications={notifications} />}
            />
            <Route path="/queue" exact render={() => <TicketQueuePage />} />
            <Route
              exact
              path="/ticket-history/:ticketId/history/:ticketVersionId?"
              component={TicketHistoryPage}
            />
            <Route path="/ticket/:ticketId" exact>
              {currentUser.canAccessTickets ? (
                <TicketPage />
              ) : (
                <Redirect to="/tickets" />
              )}
            </Route>
            <Route path="/ticketPrint/:ticketId" component={TicketPrintPage} />
          </Suspense>
        </IonRouterOutlet>
        <IonTabBar
          slot="bottom"
          className={cx("mobile-toolbar border-t", {
            hidden: !withNavigation,
          })}
        >
          <IonTabButton tab="tickets" href="/">
            <IonIcon icon={ticketOutline} />
            <IonLabel>Tickets</IonLabel>
          </IonTabButton>
          {publicConfig?.displayEventsTab && (
            <IonTabButton tab="events" href="/events" disabled={!isOnline}>
              <IonIcon icon={calendarOutline} />
              <IonLabel>Events</IonLabel>
            </IonTabButton>
          )}
          <IonTabButton tab="queue" href="/queue" disabled={!isOnline}>
            <Icon name="route" className="text-2xl" />
            <IonLabel>Queue</IonLabel>
          </IonTabButton>
          <IonTabButton
            tab="notifications"
            href="/notifications"
            disabled={!isOnline}
          >
            <NotificationIcon unreadCount={unreadCount} isMobile />
            <IonLabel>Notifications</IonLabel>
          </IonTabButton>
          <IonTabButton tab="profile" href="/me" disabled={!isOnline}>
            <IonIcon icon={personOutline} />
            <IonLabel>Profile</IonLabel>
          </IonTabButton>
        </IonTabBar>
      </IonTabs>
    </>
  );
};

export { AppMobile };
