import type { dialogFooter } from "@/components/Dialog";
import type {
  ForwardTicketProps,
  thirdPartyOption,
  thirdPartyOptionsProps,
} from "./ForwardTicket.types";
import { useState } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { ComponentLabel, Subheading } from "@urbint/silica";
import { useEgresses, useForwardTicket } from "@/api";
import { useToasts } from "@/components/Toasts";
import { Dialog } from "@/components/Dialog";
import { DropdownMultiSelect, DropdownSelect } from "@/components/Select";
import {
  DATADOG_FORWARD_TICKET_ERROR,
  EGRESS_TYPE,
} from "./FowardTicket.constants";

/**
 * Forward ticket modal
 *
 * It will show dropdowns for selecting third party and member code.
 *
 * @param ticket selected ticket data
 * @param isOpen State of modal visibility
 * @param onDidDismiss function to close modal
 * @returns void
 */

const getThirdPartyName = (
  thirdPartyOptions: thirdPartyOption[],
  thirdPartySelected: number
) =>
  thirdPartyOptions.find(
    (thirdParty: { value: number }) => thirdParty.value === thirdPartySelected
  )?.label;

const ForwardTicket = ({
  ticket: { memberCodes, id: ticketId, number },
  isOpen,
  onDidDismiss,
}: ForwardTicketProps) => {
  const { data: thirdParties } = useEgresses(EGRESS_TYPE);
  const [selectedThirdParty, setSelectedThirdParty] = useState<
    number | undefined
  >(thirdParties?.length === 1 ? thirdParties[0]?.id : undefined);
  const [selectedMembersCode, setSelectedMembersCode] = useState<
    number[] | undefined
  >(
    memberCodes?.length === 1 && memberCodes[0]?.id
      ? [memberCodes[0]?.id]
      : undefined
  );
  const { mutateAsync, isLoading } = useForwardTicket(ticketId);
  const { addToast } = useToasts();
  const isDisabled = !selectedThirdParty || !selectedMembersCode?.length;

  const memberCodeOptions =
    memberCodes?.map(({ id, key }) => ({
      value: id,
      label: key,
    })) || [];

  const thirdPartyOptions =
    thirdParties?.map(({ id, target }: thirdPartyOptionsProps) => ({
      value: id,
      label: target,
    })) || [];

  const onModalSubmit = async () => {
    if (isDisabled) {
      return;
    }
    // Get target title
    const thirdPartyName = getThirdPartyName(
      thirdPartyOptions,
      selectedThirdParty
    );

    const memberCodesName = selectedMembersCode.map(
      (selectedMemberCode) =>
        memberCodeOptions.find(
          (memberCode) => memberCode.value === selectedMemberCode
        )?.label
    );

    try {
      await mutateAsync(
        {
          egress_id: selectedThirdParty,
          member_codes: selectedMembersCode,
        },
        {
          onSuccess() {
            addToast(
              `Forwarded a ticket to ${thirdPartyName} for ${memberCodesName.toString()}.`,
              "success"
            );
            onDidDismiss();
          },
        }
      );
    } catch (error) {
      datadogRum.addError(error, {
        errorIdentifier: DATADOG_FORWARD_TICKET_ERROR,
      });
      addToast(
        `Failed to forward ticket to ${thirdPartyName}. Please try again..`,
        "error"
      );
    }
  };

  const modalFooter: dialogFooter = {
    toRender: "Forward ticket",
    onClose: onDidDismiss,
    onClick: onModalSubmit,
    submitButtonDisabled: isDisabled,
    submitButtonIsLoading: isLoading,
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onDidDismiss}
      header=""
      variant="warning"
      footer={modalFooter}
      theme={{ content: "!overflow-visible" }}
    >
      <div className="grid grid-cols-1 gap-5">
        <Subheading>{`Forward ticket #${number}?`}</Subheading>
        <div>
          <DropdownSelect
            placeholder="Select a third party"
            label="Third party"
            options={thirdPartyOptions}
            onChange={setSelectedThirdParty}
            value={selectedThirdParty}
            testId="egress-code-select"
            className="border border-solid rounded-md border-neutral-shade-border-lightmb-3"
          />
          {selectedThirdParty && (
            <ComponentLabel>
              {`This will notify 
              ${getThirdPartyName(thirdPartyOptions, selectedThirdParty)} and
              assign a new locate task to them.`}
            </ComponentLabel>
          )}
        </div>
        <DropdownMultiSelect
          placeholder="Select a member code"
          options={memberCodeOptions}
          value={selectedMembersCode}
          dropdownLabel="Member code"
          onChange={(value) => {
            setSelectedMembersCode(value);
          }}
          dataTestId="member-code-select"
        />
      </div>
    </Dialog>
  );
};

export { ForwardTicket };
