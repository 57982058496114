import type {
  DateState,
  ExpirationDateModalProps,
} from "./ExpirationDateModal.types";
import type { FormEvent } from "react";
import type { dialogFooter } from "@/components/Dialog";
import { useEffect, useState } from "react";
import { BodyText, ComponentLabel, Subheading } from "@urbint/silica";
import { Dialog } from "@/components/Dialog";
import { useToasts } from "@/components/Toasts";
import { DateTimeInput } from "@/components/DateTimeInput";
import { useUpdateExpirationDate } from "@/api";
import { getSuccessMessage, isSameDate } from "./ExpirationDateModal.utils";
import {
  MULTIPLE_TICKETS_BODY_TEXT,
  NO_DATE_SET_TEXT,
  SINGLE_TICKET_BODY_TEXT,
} from "./ExpirationDateModal.constants";

const ExpirationDateModal = ({
  isOpen,
  onClose,
  tickets,
  date,
}: ExpirationDateModalProps) => {
  const [dateTime, setDateTime] = useState<DateState>(date);
  const [sameDate, setIsSameDate] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const updateExpirationDate = useUpdateExpirationDate();
  const hasMoreThanOneTicket = tickets.length > 1;

  const { addToast } = useToasts();

  useEffect(() => {
    if (dateTime) {
      setIsSameDate(isSameDate(dateTime, date));
    }
  }, [date, dateTime]);

  const handleSubmit = async (e?: FormEvent) => {
    e?.preventDefault();

    try {
      setSubmitLoading(true);

      const mutation = await updateExpirationDate.mutateAsync(
        {
          tickets,
          expiresOn: dateTime || null,
        },
        {
          onSuccess: () => {
            setSubmitLoading(false);
            addToast(
              getSuccessMessage(dateTime, hasMoreThanOneTicket, tickets.length),
              "success"
            );
            onClose();
          },
        }
      );

      if (mutation.error) {
        throw mutation.error;
      }
    } catch (error) {
      setSubmitLoading(false);
      if (error instanceof Error) {
        addToast(`Error updating expiration date: ${error.message}`, "error");
      } else {
        addToast(`Error updating expiration date`, "error");
      }
      onClose();
    }
  };

  const handleChange = (newDateTime: Date | undefined) => {
    // "clear" the expiration date
    if (!newDateTime) {
      setDateTime(undefined);
    } else if (!sameDate) {
      setDateTime(newDateTime);
    }
  };

  const modalFooter: dialogFooter = {
    toRender: `Change date${hasMoreThanOneTicket ? "(s)" : ""}`,
    onClose,
    submitButtonIsLoading: submitLoading,
    submitButtonTestId: "edit-ticket-expiration-date",
    submitButtonDisabled: dateTime && isSameDate(dateTime, date),
    onClick: handleSubmit,
  };

  const ticketNumber = tickets[0]?.number;

  const sectionHeaderTextValue = ticketNumber
    ? `Change Expiration date for Ticket #${tickets[0]?.number}?`
    : `Change Expiration date for ${tickets.length} tickets?`;

  const bodyTextValue = ticketNumber
    ? SINGLE_TICKET_BODY_TEXT
    : MULTIPLE_TICKETS_BODY_TEXT;

  return (
    <Dialog
      isOpen={isOpen}
      onClose={onClose}
      variant="warning"
      header=""
      footer={modalFooter}
    >
      <Subheading>{sectionHeaderTextValue}</Subheading>
      <form onSubmit={handleSubmit} className="mt-2">
        <label
          htmlFor="expiration-date-input"
          className="self-center text-sm text-neutral-shade-secondary"
        >
          <ComponentLabel>Change to...</ComponentLabel>
        </label>
        <DateTimeInput
          className="mb-2"
          id="expiration-date-input"
          mode="single"
          type="datetime"
          date={dateTime}
          onDateChange={handleChange}
          staticMode
          hasClear
        />
        <BodyText>{dateTime ? bodyTextValue : NO_DATE_SET_TEXT}</BodyText>
      </form>
    </Dialog>
  );
};

export { ExpirationDateModal };
