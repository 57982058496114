import type { ServiceCenterProps } from "./ServiceCenter.types";
import { useState } from "react";
import { Icon } from "@urbint/silica";
import { useDamagePreventionAuth, useOnlineStatus } from "@/hooks";
import { addCommaInArrayValues } from "@/format";
import { Tooltip } from "@/common/Tooltip";
import { ServiceCenterModal } from "@/components/Modals";
import { Button } from "@/common/Button";

const ServiceCenter = ({ ticket }: ServiceCenterProps) => {
  const { serviceCenters = [] } = ticket || {};
  const [modalOpen, setModalOpen] = useState(false);
  const isOnline = useOnlineStatus();
  const { currentUser } = useDamagePreventionAuth();

  const currentServiceCenterNames = serviceCenters.map(
    (serviceCenterName: { name: string }) => serviceCenterName.name
  );

  const { canEditServiceCenters } = currentUser;

  return (
    <div className="flex text-black">
      {addCommaInArrayValues(currentServiceCenterNames, "-")}
      {canEditServiceCenters && (
        <>
          {isOnline && (
            <Button
              type="button"
              className="items-center text-default-on-light !p-0"
              onClick={() => setModalOpen(true)}
              data-testid="edit-service-center"
            >
              <Tooltip
                title="Edit service center"
                origin="top"
                disabled={modalOpen}
              >
                <Icon name="edit" className="ml-2" />
              </Tooltip>
            </Button>
          )}

          <ServiceCenterModal
            isOpen={modalOpen}
            onClose={() => setModalOpen(false)}
            ticket={ticket}
          />
        </>
      )}
    </div>
  );
};

export { ServiceCenter };
