import type { ServiceCenter } from "@/models";
import { useMutation } from "@tanstack/react-query";
import { UrbintApi } from "@/utils/UrbintApi";
import {
  invalidateUseFlexTicketsQuery,
  invalidateUseTicketActivityQuery,
  invalidateUseTicketQuery,
} from "@/api";

const api = new UrbintApi();

type useUpdateServiceCenterProps = {
  ticketId: number;
  serviceCenters: ServiceCenter[];
};

const setUpdateServiceCenter = async (
  ticketId: number,
  serviceCenters: ServiceCenter[]
) => {
  const response = await api.patch(
    `tickets/${ticketId}`,
    JSON.stringify({ service_centers: [...serviceCenters.map((sc) => sc.id)] })
  );

  if (response.error) {
    throw new Error(response.error.message);
  }

  return response;
};

const useUpdateServiceCenter = () =>
  useMutation(
    ({ ticketId, serviceCenters }: useUpdateServiceCenterProps) =>
      setUpdateServiceCenter(ticketId, serviceCenters),
    {
      onSuccess: (_, { ticketId }) => {
        invalidateUseTicketQuery(ticketId, true);
        invalidateUseTicketActivityQuery(ticketId);
        invalidateUseFlexTicketsQuery();
      },
    }
  );

export { useUpdateServiceCenter };
