import type {
  QuestionValue,
  TaskResponseFormQuestionInputProps,
} from "./TaskResponseFormQuestionInput.types";
import type { ReactNode } from "react";
import type { SelectOption } from "@/components/Select";
import { useCallback } from "react";
import { IonIcon } from "@ionic/react";
import { alertCircle } from "ionicons/icons";
import { QuestionAnswerType } from "@/models";

import { DropdownMultiSelect, DropdownSelect } from "@/components/Select";
import { DateTimeInput } from "@/components/DateTimeInput";
import { AutoGrowingTextArea } from "@/components/AutoGrowingTextArea";
import { TaskResponseFormQuestionHeader } from "../../TaskResponseFormQuestionHeader";
import { MultiPositiveResponseCodesSelectors } from "./MultiPositiveResponseCodesSelectors";

const TaskResponseFormQuestionInput = ({
  question,
  onChange,
  value,
  disabled,
  options,
  onBlur,
  isDirty,
  testId,
  dynamicOptions,
  hasAttemptedFormSubmission,
}: TaskResponseFormQuestionInputProps) => {
  let input: ReactNode;

  const showError =
    isDirty &&
    question.required &&
    (!value || value.length === 0) &&
    hasAttemptedFormSubmission;

  // Hybrid event that fires both the onChange and onBlur handler for use
  // with the various select components.
  const onSelectChange = useCallback(
    (val: QuestionValue) => {
      onChange(val);
      onBlur && onBlur();
    },
    [onChange, onBlur]
  );
  const errorBorder = showError
    ? "ring-system-error-30 ring-opacity-100 border-system-error-30"
    : "";
  switch (question.answerType) {
    case QuestionAnswerType.COMMENT_INPUT_FIELD:
    case QuestionAnswerType.TEXT_INPUT_FIELD:
      input = (
        <AutoGrowingTextArea
          testId={testId}
          className={`-mt-1 ${errorBorder}`}
          disabled={disabled}
          onBlur={onBlur}
          value={value as string | undefined}
          onChange={onChange}
          placeholder="Add comment..."
        />
      );
      break;
    case QuestionAnswerType.SINGLE_SELECT_PICKLIST:
      input = (
        <DropdownSelect
          className={`border rounded ${errorBorder}`}
          optionsClassName="w-full"
          placeholder="None"
          disabled={disabled}
          value={value as string | undefined}
          options={options}
          onChange={onSelectChange}
          deSelection
        />
      );
      break;
    case QuestionAnswerType.POSITIVE_RESPONSE_CODE:
      input = (
        <>
          <DropdownSelect
            testId={testId}
            className={`border rounded ${errorBorder}`}
            optionsClassName="w-full"
            placeholder="None"
            disabled={disabled}
            value={value as string | undefined}
            options={options}
            onChange={onSelectChange}
            deSelection
          />
          {value && (
            <p className="text-sm text-neutral-shade-secondary leading-tight mt-1">
              {options?.find((x) => x.value === value)?.label}
            </p>
          )}
        </>
      );
      break;
    case QuestionAnswerType.MULTI_SELECT_PICKLIST:
    case QuestionAnswerType.FOLLOW_UP_TASK:
      input = (
        <DropdownMultiSelect
          theme={{ button: errorBorder }}
          placeholder="None"
          disabled={disabled}
          value={value as string[] | undefined}
          options={options as SelectOption<string>[] | undefined}
          onChange={onSelectChange}
        />
      );
      break;
    case QuestionAnswerType.DATE_SELECTOR:
    case QuestionAnswerType.DATE_TIME_SELECTOR:
      input = (
        <DateTimeInput
          mode="single"
          type={
            question.answerType === QuestionAnswerType.DATE_SELECTOR
              ? "date"
              : "datetime"
          }
          disabled={disabled}
          date={value as string | undefined}
          onDateChange={(x) => onChange(x?.toISOString())}
          onBlur={onBlur}
          hasError={!!showError}
          staticMode
        />
      );
      break;

    case QuestionAnswerType.MULTI_PR_QUESTION:
      input = (
        <MultiPositiveResponseCodesSelectors
          className={`border rounded ${errorBorder}`}
          value={value as string | undefined}
          onChange={onSelectChange}
          placeholder="Do not send response"
          disabled={disabled}
          showError={!!showError}
          memberCodesWithPrCodes={
            dynamicOptions
              ? dynamicOptions[question.id].memberCodesWithPrCodes
              : []
          }
        />
      );
      break;
    default:
      input = <p>Unhandled answer type: {question.answerType}</p>;
  }

  return (
    <div className="mb-4">
      <TaskResponseFormQuestionHeader className="flex items-center">
        {question.answerType === QuestionAnswerType.MULTI_PR_QUESTION
          ? ""
          : question.text}
        {!question.required && (
          <span className="text-neutral-shade-tertiary font-small ml-auto font-normal">
            optional
          </span>
        )}
      </TaskResponseFormQuestionHeader>
      {input}
      {showError && (
        <p className="flex items-center">
          <IonIcon
            icon={alertCircle}
            className="text-system-error-40 text-lg mr-1"
          />
          <span
            className="text-neutral-shade-secondary font-small"
            data-testid="field-required-text"
          >
            This field is required.
          </span>
        </p>
      )}
    </div>
  );
};

export { TaskResponseFormQuestionInput };
