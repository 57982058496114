import type { ServiceCenter } from "@/models";

const serviceCenterIds = (serviceCenters: ServiceCenter[]) =>
  serviceCenters.map((serviceCenter) => serviceCenter.id);
const serviceCenterOptions = (serviceCenters: ServiceCenter[]) =>
  serviceCenters.map((serviceCenter) => ({
    label: serviceCenter.name,
    value: serviceCenter.id,
  }));

export { serviceCenterIds, serviceCenterOptions };
